const cookie = {
    /* 设置cookie */
    setCookie(name, value, timer = 1000 * 60 * 60 * 24*365) {
      const exp = new Date();
      const type = Object.prototype.toString.call(value);
      value = ["[object Object]", "[object Array]"].includes(type) ? JSON.stringify(value) : value
      exp.setTime(exp.getTime() + timer);
      document.cookie = `${name}=${value};expires=${exp.toGMTString()}`;
    },
  
    /* 获取cookie */
    getCookie(name) {
      const cname = `${name}=`;
      const cookie = document.cookie
        .split(";")
        .find((item) => item.trim().indexOf(cname) === 0);
          //trim 去除两边空格
      if (!cookie) {
        return undefined;
      }
  
      try {
        return JSON.parse(cookie.trim().replace(cname, ""));
      } catch {
        return cookie.trim().replace(cname, "");
      }
    },
  
    /* 删除cookie */
    clearCookie(name) {
      this.setCookie(name, "", -1);
    },
  };
  
  export { cookie };
  