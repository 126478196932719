
import { ref, computed, watch , onMounted} from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { ActionSheet, Button, Toast, Icon } from "vant";
import { Field, CellGroup, Dialog } from "vant";

import {
  centerNumber,
  privacyNumber,
  trialNumber,
  trialPay,
  sms,
  smsCheckcode,
  productBuy,
  productProList,
} from "@/api/request.js";
import dayjs from "dayjs";
import { cookie } from "@/utils/cookie.js";
import wx from "weixin-js-sdk";
export default {
  components: {
    [ActionSheet.name]: ActionSheet,
    [Field.name]: Field,
    [CellGroup.name]: CellGroup,
    [Button.name]: Button,
    [Icon.name]: Icon,
  },
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  setup() {
    const route = useRoute();
    const store = useStore();
    const title = ref<string>("");
    const back = ref<string>();
    const name = ref<string>("");
    const src = ref("");
    const text = ref<string>("");
    const businessId = ref<number>(0);
    const explain = ref("");
    const name2 = ref<string>("");
    const checked = ref<number>(-1);
    const block = ref<any>([]);
    const trial = ref(route.query.trial);
    const label = ref<string>("手机号");
    const placeholder = ref<string>("请输入手机号");
    const show = ref(false);
    const number = ref<number>();
    const number2 = ref<number>();
    const smsCode = ref<number>();
    const code = 123456;
    const regExp = ref<any>(
      /^1(3\d|4[5-9]|5[0-35-9]|6[567]|7[0-8]|8\d|9[0-35-9])\d{8}$/
    );
    const xnumber = ref("");
    const oid = ref();
    const refname = ref(null);
    const off = ref(null);
    const Openid = computed(() => store.state.openid);
    const scode = ref<string | number>("发送验证码");
    const count = ref<number>(20);
    const patterns = computed(() => {
      return regExp.value.test(number.value);
    });
    const patterns2 = computed(() => {
      return regExp.value.test(number2.value);
    });

    const numbers = computed(() => {
      return (
        regExp.value.test(number2.value) &&
        regExp.value.test(number.value) &&
        xnumber.value != ""
      );
    });

    if (route.query.trial == "1") {
      title.value = "体验中间号";
      back.value = "background: linear-gradient(45deg, #a2f1f5, #14b0b9);";
      name.value = "选择中间号";
      name2.value = "请输入号码";
      src.value = require("../../assets/images/trial/trial1.png");
      explain.value =
        "中间号作为第3方号码，为号码1，2之间的沟通建立桥梁，号码1，2相互拨打中间号都可呼叫对方，而来电显示的号码是中间号。隐私号体验时限均为3小时，过期号码即收回。";
      label.value = "号码1";
      placeholder.value = "请输入手机号";
      text.value = " 如无特殊说明，中间号体验时限均为3小时，过期号码即收回";
      let token: any = cookie.getCookie("trial1") as any;
      console.log(token, "cookie");

      if (token == undefined) {
        (number.value as any) = "";
        (number2.value as any) = "";
        (xnumber.value as any) = "";
      } else {
        (number.value as any) = token[0];
        (number2.value as any) = token[1];
        (xnumber.value as any) = token[2];
      }
      businessId.value = 5;
    }
    if (route.query.trial == "2") {
      title.value = "体验隐私号";
      back.value = "background: linear-gradient(45deg, #f3bcb4, #e66652);";
      name.value = "选择隐私号";
      name2.value = "请输入体验者号";
      src.value = require("../../assets/images/trial/trial2.png");
      explain.value =
        "隐私号拥有者通过在需要拨打号码的前面增加 95013，即可享受号码隐藏，对方的来电显示您的隐私号，对方如果拨打隐私号，会转接到您的手机接听。隐私号体验时限均为3小时，过期号码即收回。";
      label.value = "手机号";
      placeholder.value = "请输入您想体验隐私号的手机号码";
      text.value = " 如无特殊说明，隐私号体验时限均为3小时，过期号码即收回";
      let token: any = cookie.getCookie("trial2") as any;
      console.log(token, "cookie");

      if (token == undefined) {
        (number.value as any) = "";
        (xnumber.value as any) = "";
      } else {
        (number.value as any) = token[0];
        (xnumber.value as any) = token[1];
      }
      businessId.value = 4;
    }
    if (route.query.trial == "3") {
      title.value = "快速体验";
      back.value = "background: linear-gradient(45deg, #a3c8fa, #1873f2);";
      name.value = "选择体验号";
      name2.value = "验证手机号";
      src.value = require("../../assets/images/trial/trial3.png");
      explain.value =
        "如无特殊说明，体验时限均为两个自然日，被叫全免费，各模式设置均与正式号码一致。过期后号码系统自动收回，将不能继续使用。体验号包含20分钟主叫通话时长，10条短信，10次录音，10次留言，并发，监听，电话拦截，黑白名单，并发，分机，坐席，直线分机，通讯库。";
      businessId.value = 3;
      text.value = " 如无特殊说明，快捷体验时限均为两个自然日，过期号码即收回";
      count.value = 100;
    }

    const list = ref([]);
    const hideshow = ref(true);
    const docmHeight = ref(
      document.documentElement.clientHeight || document.body.clientHeight
    );
    const showHeight = ref(
      document.documentElement.clientHeight || document.body.clientHeight
    );
    watch(showHeight, (newName, oldName) => {
      if (docmHeight.value > showHeight.value) {
        //隐藏
        hideshow.value = false;
      } else {
        //显示
        hideshow.value = true;
      }
    });
    onMounted(()=>{
       //监听事件
    window.onresize = ()=>{
        return(()=>{
            showHeight.value = document.documentElement.clientHeight || document.body.clientHeight;
        })()
    }
    }) 
   


    // 号码数据
    const gettrialNumber = async () => {
      const data = await trialNumber(
        businessId.value + `?count=${count.value}`
      );
      list.value = data.data;
      console.log(data, "data");
    };
    gettrialNumber();
    const blocks = (e: any) => {
      block.value.push(e);
    };
    console.log(dayjs("1985462452").format("YYYY_MM_DD hh:m:ss"));
    //中间号
    const getcenterNumber = async () => {
      const data = await centerNumber({
        phone: number.value, //手机号1
        phone1: number2.value, //手机号2
        centre_number: xnumber.value, //中间号
      });
      if (data.success == false) {
        return Toast(data.message);
      }
      cookie.setCookie(
        "trial1",
        [number.value, number2.value, xnumber.value],
        1000 * 60 * 60*3
      );
      console.log(data, "hao");
    };
    // 隐私号
    const getprivacyNumber = async () => {
      const data = await privacyNumber({
        phone: number.value, //手机号1
        number: xnumber.value, //隐私号
      });
      if (data.success == false) {
        return Toast(data.message);
      }
      cookie.setCookie("trial2", [number.value, xnumber.value], 1000 * 60 * 60*3);
      console.log(data, " #$##");
    };
    // getprivacyNumber();
    // 选择号码
    const getList = (i: number, item: any) => {
      if (item.status != 1) {
        block.value[i].onclick = null;
        return;
      }
      (checked.value as number) = i;
      show.value = false;
      xnumber.value = item.number;
      console.log(item);
      getproductProList(item.niceType);
    };
    // 选套餐
    const getproductProList = async (id: number) => {
      const data = await productProList(id + "?businessId=3");
      console.log(data);
      if (data.success == true) {
        oid.value = data.data[0].oid;
      }
    };

    //发送验证码
    const getsms = async () => {
      if (!patterns.value) {
        return Toast("请输入正确的手机号");
      }
      const data = await sms({ phone: number.value });
      console.log(data);

      Toast({ message: "验证码发送" + data.message, className: "toast" });
      if (data.success == false) {
        return;
      }
      let num: any = 60;
      var time = setInterval(() => {
        scode.value = (num as string) + "s";
        num as number;
        num--;
        if (num == -1) {
          clearTimeout(time);
          scode.value = "发送验证码";
        }
      }, 1000);
    };

    //校验验证码
    const getsmsCheckcode = async () => {
      const data = await smsCheckcode({
        phone: number.value,
        code: smsCode.value,
      });
      if (data.success == false) {
        return Toast({ message: data.message, className: "toast" });
      }
      // getpay(3);
      getproductBuy(3);
    };

    //支付
    const getpay = async (id: number) => {
      const data = await trialPay({
        phone: number.value, //手机号
        number: xnumber.value, //购买号码
        businessId: id, //4隐私号5中间号
        openId: Openid.value, //openid
      });
      console.log(data, "app openid", Openid.value);

      wx.config({
        debug: false, // 开启调试模式,调用的所有 api 的返回值会在客户端 a
        appId: data.data.appId, // 必填，公众号的唯一标识
        timestamp: data.data.timeStamp, // 必填，生成签名的时间戳
        nonceStr: data.data.nonceStr, // 必填，生成签名的随机串
        signature: data.data.paySign, // 必填，签名
        jsApiList: ["chooseWXPay"], // 必填，需要使用的 JS 接口列表
      });
      wx.ready(() => {
        wx.checkJsApi({
          jsApiList: ["chooseWXPay"],
          success: function (res: any) {
            console.log("seccess");
            console.log("hskdjskjk", res);
          },
          fail: function () {
            console.log("fail");
          },
        });
      });
      wx.chooseWXPay({
        appid: data.data.appId,
        timestamp: data.data.timeStamp,
        nonceStr: data.data.nonceStr,
        package: data.data.package,
        signType: data.data.signType,
        paySign: data.data.paySign,
        success: function (res: any) {
          console.log(res);
          if (trial.value == "1") {
            getcenterNumber();
            Dialog({
              title: "中间号",
              message: `您现在可以体验中间号了。使用号码1或号码2拨打${xnumber.value}都可以呼叫对方，被叫方的来电显示为${xnumber.value}`,
            });
          }
          if (trial.value == "2") {
            getprivacyNumber();
            Dialog({
              title: "隐私号",
              message: `您现在可以体验隐私号了。被叫：当 ${xnumber.value} 被拨打的时候，您${number.value}的手机响铃主叫：使用${number.value}的手机在拨打号码的前面输入95013`,
            });
          }
          Toast("支付成功");
        },
        cancel: function () {
          Toast("已取消支付!");
        },
        fail: function () {
          Toast("支付失败，请重试!");
        },
      });
    };
    const getproductBuy = async (id: number) => {
      const data = await productBuy({
        mobile: number.value as any, //手机号码
        productId: oid.value as any, //套餐id
        openId: Openid.value as any,
        payType: 7 as any, //6云币支付7微信js支付
        number: xnumber.value as any, //号码，多个号码用，隔开
        cid: "", //当用户购买过站点时传，第一次购买可不传

        businessId: id, //4隐私号5中间号
      });
      console.log(
        data,
        "app openid",
        Openid.value,
        oid.value,
        number.value,
        xnumber.value
      );

      wx.config({
        debug: false, // 开启调试模式,调用的所有 api 的返回值会在客户端 a
        appId: data.data.appId, // 必填，公众号的唯一标识
        timestamp: data.data.timeStamp, // 必填，生成签名的时间戳
        nonceStr: data.data.nonceStr, // 必填，生成签名的随机串
        signature: data.data.paySign, // 必填，签名
        jsApiList: ["chooseWXPay"], // 必填，需要使用的 JS 接口列表
      });
      wx.ready(() => {
        wx.checkJsApi({
          jsApiList: ["chooseWXPay"],
          success: function (res: any) {
            console.log("seccess");
            console.log("hskdjskjk", res);
          },
          fail: function () {
            console.log("fail");
          },
        });
      });
      wx.chooseWXPay({
        appid: data.data.appId,
        timestamp: data.data.timeStamp,
        nonceStr: data.data.nonceStr,
        package: data.data.package,
        signType: data.data.signType,
        paySign: data.data.paySign,
        success: function (res: any) {
          console.log(res);
          Toast("支付成功");
          window.location.href = "https://centrex.av5g.cn/";
        },
        cancel: function () {
          Toast("已取消支付!");
        },
        fail: function () {
          Toast("支付失败，请重试!");
        },
      });
    };

    const getName = () => {
      if (trial.value == "1") {
        if (cookie.getCookie("trial1")) {
          (refname.value as any).onclick = null;
          return;
        }
      }
      if (trial.value == "2") {
        if (cookie.getCookie("trial2")) {
          (refname.value as any).onclick = null;
          return;
        }
      }

      show.value = true;
    };

    const confirm = () => {
      if (xnumber.value == "") {
        return Toast("请选择号码");
      }
      if (trial.value == "1") {
        if (cookie.getCookie("trial1")) {
          Dialog({
            title: "中间号",
            message: `您现在可以体验中间号了。使用号码1或号码2拨打${xnumber.value}都可以呼叫对方，被叫方的来电显示为${xnumber.value}`,
          });
          (off.value as any).onclick = null;

          return;
        }
        if (!patterns.value) {
          return Toast("号码1手机格式错误");
        }
        if (!patterns2.value) {
          return Toast("号码2手机格式错误");
        }
        getpay(5);
      }

      if (trial.value == "2") {
        if (cookie.getCookie("trial2")) {
          Dialog({
            title: "隐私号",
            message: `您现在可以体验隐私号了。被叫：当 ${xnumber.value} 被拨打的时候，您${number.value}的手机响铃主叫：使用${number.value}的手机在拨打号码的前面输入95013`,
          });
          (refname.value as any).onclick = null;
          (off.value as any).onclick = null;
          return;
        }
        if (!patterns.value) {
          return Toast("请输入正确的手机号");
        }
        getpay(4);
      }

      if (trial.value == "3") {
        if (!patterns.value) {
          return Toast("请输入正确的手机号");
        }

        getsmsCheckcode();
      }
      console.log(22);
    };

    return {
      title,
      back,
      name,
      src,
      explain,
      hideshow,
      name2,
      blocks,
      text,
      getName,
      checked,
      trial,
      list,
      show,
      label,
      placeholder,
      number,
      number2,
      xnumber,
      smsCode,
      getsms,
      scode,
      off,
      numbers,
      getList,
      confirm,
    };
  },
};
